const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const theme = {
  breakpoints: {
    up: function (breakpoint: string) {
      return `@media (min-width:${breakpoints[breakpoint]}px)`
    },
  },
}

export default theme
